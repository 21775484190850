import React from "react"
import Layout from "../components/layout"
import Switcher from "../components/Switcher"
import PageHeader from "../components/PageHeader"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

function IndexPage() {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativePath: { eq: "stage/1.jpg" } }) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        keywords={[`Jason`, `Timbuktu`, `Diakité`, `A Drop Of Midnight`]}
        title="The Stage Performance"
      />
      <div className="flex flex-col font-sans min-h-screen text-black bg-yellow-500">
        <main className="flex flex-col md:justify-center max-w-5xl mx-auto px-4 py-8 md:p-8 w-full">
          <div className="">
            <PageHeader />
            <Switcher />
            <div className="flex flex-col mt-4 items-center max-w-3xl mx-auto">
              <div className="tickets mb-8">
                <button className="bg-black text-yellow-400 font-semibold py-2 px-4 rounded inline-flex items-center">
                  <span className="mr-1">
                    <a href="https://www.harlemstage.org/waterworks-a-drop-of-midnight">
                      Get tickets
                    </a>
                  </span>
                </button>
              </div>
              <div className="w-full ">
                <Img fluid={data.allFile.edges[0].node.childImageSharp.fluid} />
              </div>
              <div className="w-full mt-8 text-lg">
                <p>
                  Harlem Stage commissioned WaterWorks artist Jason ‘Timbuktu’
                  Diakité´s autobiographical theater project “A Drop of
                  Midnight” is directed by Jonathan McCrory, composed by Erik
                  Hjärpe, and accompanied by live band, The Rakiem Walker
                  Project. Diakité is one of Sweden’s biggest hip hop artists
                  and a best-selling author. A Drop of Midnight takes us on a
                  journey from the deep south and slave plantations by way of
                  Harlem to a small Swedish town. Through storytelling and live
                  music, the play explores identity, ancestry, migration, and
                  bigotry.
                </p>

                <p>
                  The original live performance in Swedish in 2017-2018 (En
                  Droppe Midnatt) was directed by Farnaz Arbabi, processed by
                  Eva-Maria Dahlin with scenography by Jenny Kronberg.
                </p>
              </div>
              <div></div>
            </div>
            <div className="max-w-3xl mx-auto">
              <div className="embed-responsive aspect-ratio-16/9">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/YVDtImRGa3Y"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </main>
      </div>
    </Layout>
  )
}

export default IndexPage
